import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {userDataSelector} from "../../redux/selectors/userSelectors";
import {useLogout} from "../../hooks/useLogout";

const FrontNavbar = () => {

    const userSelector = useSelector(userDataSelector)
    const logout = useLogout()

    return (
        <nav className="bg-primary-300">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="flex relative h-16 items-center justify-between">
                    <div className="flex flex-shrink-0 items-center">
                        <Link to={"/"}>
                            <h1 className={"text-white font-bold text-sm lg:text-xl"}>
                                <span className={"text-secondary-300"}>Bene</span>šov<span
                                className={"text-secondary-300"}><sup className={"icon-benesov text-sm "}></sup></span>Bene<span className={"text-secondary-300"}>fit!</span>
                            </h1>
                        </Link>
                    </div>
                    <div className="sm:ml-6">
                        <div className="flex space-x-4 flex-nowrap">
                            {userSelector ?
                                <Fragment>
                                    <Link to={'/portal'}
                                          className="text-gray-300 hover:text-white px-2 py-2 text-sm font-medium">
                                        Můj účet
                                    </Link>
                                    <button onClick={() => logout()}
                                          className="text-gray-300 hover:text-white px-2 py-2 text-sm font-medium">
                                        Odhlásit se
                                    </button>
                                </Fragment>
                                :
                                <Fragment>
                                    <Link to={'/prihlaseni'}
                                          className="text-gray-300 hover:text-white px-2 py-2 text-sm font-medium">
                                        Již mám účet
                                    </Link>
                                    <Link to={'/registrace'}
                                          className="text-gray-300 hover:text-white px-2 py-2 text-sm font-medium">
                                        Chci se registrovat
                                    </Link>
                                </Fragment>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default FrontNavbar;