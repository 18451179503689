import React from 'react';
import SidebarLink from "compontents/Sidebar/SidebarLink";
import {portalSidebarLinks} from "variables/portalSidebarLinks";
import FeatherIcons from "feather-icons-react";
import {useLogout} from "../../hooks/useLogout";

const Sidebar = () => {

    const logout = useLogout()

    return (
        <div className={"min-h-[calc(100vh-64px)] bg-secondary-300 w-1/6 hidden lg:block"}>
            <div>
                <ul className={"py-3"}>
                    {portalSidebarLinks.map((item, index) => (
                        <SidebarLink
                            key={index}
                            label={item.label}
                            link={item.link}
                            icon={item.icon}
                        />
                    ))}
                    <li>
                        <button onClick={() => logout()} className={"flex w-full justify-items-start items-center px-7 py-5 text-primary-300 hover:bg-yellow-300"}>
                            <FeatherIcons icon={"log-out"}/> <span className={"ml-4 text-sm"}>Odhlásit se</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;