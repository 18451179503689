import React from 'react';
import PortalHeader from "../../compontents/PortalHeader";

const Support = () => {
    return (
        <div>
            <PortalHeader
                headline={"Podpora"}
            />
        </div>
    );
};

export default Support;