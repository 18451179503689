import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getChild} from "../../../api/CitizenApi";
import Loader from "../../../compontents/Loader";
import CitizenDetail from "../../../compontents/Citizen/CitizenDetail";
import PortalHeader from "../../../compontents/PortalHeader";

const FamilyMemberDetail = () => {

    const [searchParams] = useSearchParams();
    const [child, setChild] = useState(null);
    const navigate = useNavigate();
    let {id: childId} = useParams();

    useEffect(() => {

        if (!childId) {
            toast.info("ID nenalezeno")
            navigate('/portal/clenove-rodiny')
        }

        fetchChild(childId).then(() => console.info("Data loaded.."))

    }, [navigate, searchParams, childId])

    async function fetchChild(childId) {
        try {
            const data = await getChild(childId)
            console.log(data)
            setChild(data.citizen)
        } catch (e) {
            console.error(e.message || e)
            toast.error("Při zpracování požadavku došlo k chybě.")
        }
    }

    return (
        <div>
            <PortalHeader
                headline={`Členové rodiny / ${child && child.firstName + ' ' + child.lastName}`}
                children={<div className={"mb-8"}>
                    <Link to={'/portal/clenove-rodiny'} className={"button-primary"}>Zpět
                        na výpis členů</Link>
                </div>}
            />
            {child ? <CitizenDetail citizen={child}/> : <Loader/>}
        </div>
    );
};

export default FamilyMemberDetail;