import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/app.scss';
import App from './App';
import RouterFactory from "./compontents/routes/RouterFactory";
import {Provider as ReduxProvider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
    <React.Fragment>
        <ReduxProvider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <App>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                />
                <RouterFactory/>
            </App>
            </PersistGate>
        </ReduxProvider>
    </React.Fragment>
);