import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const FormInput = ({name, type, label, defaultValue, register, readonly, required, errors}) => {

    const renderCheckbox = () => (
        <input
            type={type}
            id={name}
            checked={defaultValue}
            readOnly={readonly}
            {...register(name, {required})}
            className={`border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-3 mr-2 ${readonly ? 'bg-gray-300' : 'bg-gray-50'}`}
        />
    )

    const renderTextInput = () => (
        <input
            type={type}
            id={name}
            placeholder={defaultValue && defaultValue}
            readOnly={readonly}
            {...register(name, {required})}
            className={`border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 ${readonly ? 'bg-gray-300' : 'bg-gray-50'}`}
        />
    )

    return (
        <Fragment>
            {type === 'checkbox' &&
                renderCheckbox()
            }
            <label htmlFor={name} className={`text-sm font-medium text-gray-900 ${type !== 'checkbox' && 'block mb-2'} ${required && 'required'}`}>
                {label}
            </label>
            {type !== 'checkbox' &&
                renderTextInput()
            }
            {errors && errors[name] && (
                <p role={'alert'} className={'text-sm text-red-600 block'}>{errors[name].message || 'Špatně vyplněné pole'}</p>
            )}
        </Fragment>
    );
};


FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'email', 'password', 'checkbox']).isRequired
};

export default FormInput;