import React from 'react';
import FormInput from "../Input/FormInput";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {yupResolver} from "@hookform/resolvers/yup";
import {recoveryPasswordValidation} from "../../../variables/formsValidation/recoveryPasswordValidation";
import {setNewPasswordFromAccount} from "../../../api/AuthApi";
import {useLogout} from "../../../hooks/useLogout";

const ChangePasswordForm = () => {

    const {
        register,
        handleSubmit,
        formState,
        formState: {errors},
    } = useForm({
        mode: "onBlur",
        resolver: yupResolver(recoveryPasswordValidation),
    });

    const onSubmit = async (data, e) => {
        try {
            const response = await setNewPasswordFromAccount(data)
            if (response) {
                if (formState.isSubmitSuccessful) {
                    e.target.reset();
                }
                toast.success('Změna hesla byla úspěšná');
            } else {
                toast.error('Špatné původní heslo');
            }
        } catch (err) {
            toast.error("Došlo k chybě")
            console.error(err.message || err)
        }
    }

    return (
        <div>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    name={"oldPassword"}
                    type={"password"}
                    label={"Současné heslo"}
                    required={true}
                    errors={errors}
                    register={register}
                />
                <FormInput
                    name={"password"}
                    type={"password"}
                    label={"Nové heslo"}
                    required={true}
                    errors={errors}
                    register={register}
                />
                <FormInput
                    name={"passwordVerify"}
                    type={"password"}
                    label={"Nové heslo pro kontrolu"}
                    required={true}
                    errors={errors}
                    register={register}
                />
                <button type="submit"
                        className="button-primary">
                    Změnit heslo
                </button>
                <div className="flex items-center justify-between">
                    <button onClick={useLogout('/zapomenute-heslo')}
                          className="text-sm font-medium text-primary-600 hover:underline">
                        Zapomněli jste heslo?
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChangePasswordForm;