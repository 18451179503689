import React, {useState, useEffect} from 'react';
import Loader from "../../compontents/Loader";
import PortalHeader from "../../compontents/PortalHeader";
import {NumericFormat} from "react-number-format";
import {toast} from "react-toastify";
import {getCreditTransactions} from "../../api/CreditTransactionApi";
import moment from "moment";
import BoxContent from "../../compontents/BoxContent";

const TransactionHistory = () => {

    const [data, setData] = useState(null);
    const [paginator, setPaginator] = useState(null);
    let page = 0;
    let offset = 0;
    const limit = 10;

    useEffect(() => {
        getCreditTransactionList(page, offset, limit).then(() => {
			console.info("Data loaded..")
		});
    }, [offset, page]);


    async function getCreditTransactionList(page, offset, limit) {
        try {
            const data = await getCreditTransactions(page, offset, limit);
            setPaginator(data.paginator);
            setData(data.creditTransactionList);
        } catch (e) {
            console.error(e.message || e)
            toast.error("Při zpracování požadavku došlo k chybě.")
        }
    }


    const renderData = () => {
        return data.map((item, index) => (
            <div key={index} className={"tableContent"}>
                <div className={"custom-col"}>
                    <div className={"block"}>
                        <span
                            className={`icon-${item.touchPoint ? item.touchPoint.key : 'benesov'} text-3xl text-secondary-300 w-4 h-4 bg-primary-300 rounded-full p-1`}></span>
                    </div>
                </div>
                <div className={"custom-col font-bold"}>{item.touchPoint ? item.touchPoint.name : null}</div>
                <div className={"custom-col text-lg"}>{moment(item.datetime).local().format("DD.MM.YYYY HH:mm")}</div>
                <div
                    className={`custom-col font-bold text-lg ${item.type === "add" ? "text-green-500" : "text-red-500"}`}>
                    <NumericFormat
                        value={item.amount}
                        decimalScale={2}
                        allowLeadingZeros
                        thousandSeparator=","
                        suffix={' Bene'}
                        displayType={'text'}
                    />
                </div>
                <div className={"custom-col"}>{item.description}</div>
                <div className={"custom-col font-bold text-lg"}>
                    <NumericFormat
                        value={item.balance}
                        decimalScale={2}
                        allowLeadingZeros
                        thousandSeparator=","
                        suffix={' Bene'}
                        displayType={'text'}
                    />
                </div>
            </div>
        ));
    }

    const renderPaginator = () => {
        if (paginator && paginator.totalPages > 1) {
            return (
                <div className={'mt-8 text-center space-x-2'}>
                    <span>Stránka {paginator.currentPageNumber + 1}/{paginator.totalPages}</span>
                    {paginator.currentPageNumber > 0 && <button onClick={() => previousPage()}>Předchozí</button>}
                    {(paginator.currentPageNumber + 1) < paginator.totalPages &&
                        <button onClick={() => nextPage()}>Další</button>}
                </div>
            )
        }
    }

    const previousPage = () => {
        let offset = (paginator.currentPageNumber - 1) * limit;
        getCreditTransactionList(paginator.currentPageNumber - 1, offset, limit);
    }

    const nextPage = () => {
        let offset = (paginator.currentPageNumber + 1) * limit;
        getCreditTransactionList(paginator.currentPageNumber + 1, offset, limit);
    }

    return (
        <div>
            <PortalHeader headline={"Historie transakcí"}/>
            <BoxContent additionalClass={"max-w-5xl mx-auto"}>
                {paginator && data ?
                    <>
                        {paginator.totalItems > 0 ?
                            <div className={"custom-table"}>
                                <div className={"tableHeader"}>
                                    <div className={"custom-col"}></div>
                                    <div className={"custom-col"}>Vstup</div>
                                    <div className={"custom-col"}>Datum</div>
                                    <div className={"custom-col"}>Množství</div>
                                    <div className={"custom-col"}>Poznámka</div>
                                    <div className={"custom-col"}>Zůstatek</div>
                                </div>
                                {renderData()}
                            </div>
                            :
                            <div className={"text-center text-xl"}>
                                <div>Aktuálně zde nejsou žádná data k zobrazení.</div>
                            </div>
                        }
                        {renderPaginator()}
                    </>
                    :
                    <div className={"flex w-full justify-center"}>
                        <Loader/>
                    </div>
                }
            </BoxContent>
        </div>
    );
};

export default TransactionHistory;