import React, {useEffect} from 'react';
import PortalNavbar from "../Navbar/PortalNavbar";
import Sidebar from "../Sidebar/Sidebar";
import {useDispatch} from "react-redux";
import {authenticateUser} from "../../redux/slices/userslice";
import {useAuthCheckAndRedirect} from "../../hooks/useAuthCheckAndRedirect";

const PortalLayout = ({children}) => {

    useAuthCheckAndRedirect();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authenticateUser())
    }, [dispatch])

    return (
        <div className={"module-portal"}>
            <PortalNavbar/>
            <div className={"flex"}>
                <Sidebar/>
                <main className={"bg-gray-200 min-h-screen lg:min-h-full lg:w-5/6 w-full p-7"}>
                    <div className={"main-content"}>
                        {children}
                    </div>
                    <footer className={"flex justify-between"}>
                        <div>
                            Vytvořil <a href="https://deepvision.cz/">DEEP VISION s.r.o.</a> pro město Benešov a TS Benešov.
                        </div>
                        <div>
                            <span className={"text-gray-400"}>{process.env.REACT_APP_BITBUCKET_VERSION}</span>
                        </div>
                    </footer>
                </main>
            </div>
        </div>
    );
};

export default PortalLayout;