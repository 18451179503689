import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Konfigurace pro redux-persist
const persistConfig = {
    key: 'benesov',
    storage,
    // whitelist: ['someReducer']
    // blacklist: ['someTemporaryReducer']
};

// Obalení rootReducer pomocí persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Konfigurace middleware
const middleware = [thunk];
/*if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}*/
middleware.push(createLogger());

// Vytvoření store s middleware a persistedReducer
const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware)
);

// Vytvoření persistor objektu
const persistor = persistStore(store);

export { store, persistor };
