import React, {useEffect, useState} from 'react';
import PortalHeader from "../../../compontents/PortalHeader";
import {getCitizen} from "../../../api/CitizenApi";
import {toast} from "react-toastify";
import Loader from "../../../compontents/Loader";
import CitizenDetail from "../../../compontents/Citizen/CitizenDetail";
import BoxContent from "../../../compontents/BoxContent";
import ChangePasswordForm from "../../../compontents/Forms/Portal/ChangePasswordForm";
import Badge from "../../../compontents/Badge";

const MyAccount = () => {

	const [citizen, setCitizen] = useState(null);
	const [carPlates, setCarPlates] = useState(null);

	useEffect(() => {
		getMe().then((data) => {
			setCitizen(data.citizen)
			setCarPlates(data.carPlates)
		})
	}, [])

	async function getMe() {
		try {
			return await getCitizen()
		} catch (e) {
			console.error(e.message || e)
			toast.error("Při zpracování požadavku došlo k chybě.")
		}
	}

	return (
		<div>
			<PortalHeader headline={"Můj účet"}/>
			<div className="grid grid-cols-3 space-x-5">
				<div className="col-span-2">
					{citizen ?
						<CitizenDetail
							citizen={citizen}
						/>
						:
						<div className={"flex justify-center"}>
							<Loader/>
						</div>
					}
				</div>
				<div className="col-span-1">
					<BoxContent label={"Změna hesla"}>
						<ChangePasswordForm/>
					</BoxContent>
					{carPlates && carPlates.length > 0 &&
						<BoxContent label={"Registrovaná vozidla"}>
							<div>
								{carPlates.map((item, index) => (
									<div className={"flex justify-between"} key={index}>
										<div>
											{item.carPlateNumber}
										</div>
										<div>
											{item.active ?
												<Badge type={"success"} text={"Aktivní"}/>
												:
												<Badge type={"error"} text={"Neaktivní"}/>
											}
										</div>
									</div>
								))}
							</div>
						</BoxContent>
					}
				</div>
			</div>
		</div>
	);
};

export default MyAccount;