import {ApiAxios} from "../axios/axiosConfig";

export async function getCreditTransactions(page, offset, limit) {
	return ApiAxios.get(`/creditTransactions?page=${page}&offset=${offset}&limit=${limit}`).then(res => {
		if (res.data) {
			return res.data.data;
		}
	}).catch(err => {
		console.error(err.message || err)
		return false
	})
}