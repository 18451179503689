import React from 'react';
import {Link} from "react-router-dom";
import Badge from "../Badge";

const ChildrenList = ({data}) => {
		return (
			<table className="table-auto w-full">
				<thead>
				<tr className={"text-left text-gray-400"}>
					<th></th>
					<th>Jméno</th>
					<th>E-mail</th>
					<th>Telefon</th>
					<th>V systému od</th>
					<th>Stav</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				{data.map((item, index) => (
					<tr key={index}>
						<td>
							<div
								className="rounded-full h-8 w-8 text-sm flex items-center justify-center bg-gradient-to-r from-primary-100 to-primary-500 text-white font-semibold">
								{item.firstName[0]}{item.lastName[0]}
							</div>
						</td>
						<td>
							<div>
								<h3 className="text-lg text-gray-800 font-semibold">{item.firstName} {item.lastName}</h3>
								<p className="text-gray-500 text-sm">ID: {item._id.substring(0, 8)}...</p>
							</div>
						</td>
						<td><p className="text-gray-600">{item.email}</p></td>
						<td><p className="text-gray-600">{item.phone}</p></td>
						<td>
							{new Date(item.createdAt).toLocaleDateString()}
						</td>
						<td><Badge
							type={item.active ? 'success' : 'danger'}
							text={item.active ? 'Aktivní' : 'Neaktivní'}
						/></td>
						<td>
							<Link to={`/portal/clenove-rodiny/${item._id}`} className="button-primary">
								Detail
							</Link>
						</td>
					</tr>
				))}
				</tbody>
			</table>
		);
	}
;

export default ChildrenList;
