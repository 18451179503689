import React from 'react';
import FormInput from "../Input/FormInput";
import {useForm} from "react-hook-form";

const AccountForm = ({defaultValues}) => {

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues
    });

    const onSubmit = async (data) => {
        // todo editace účtu -> PUT /portal/citizen
        console.log(data)
    }

    return (
        <div>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                    name={"firstName"}
                    type={"text"}
                    label={"Jméno"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <FormInput
                    name={"lastName"}
                    type={"text"}
                    label={"Příjmení"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <FormInput
                    name={"email"}
                    type={"text"}
                    label={"E-mail"}
                    required={true}
                    errors={errors}
                    readonly={false}
                    register={register}
                />
                <FormInput
                    name={"phone"}
                    type={"text"}
                    label={"Telefon"}
                    required={true}
                    errors={errors}
                    readonly={false}
                    register={register}
                />
                <FormInput
                    name={"street"}
                    type={"text"}
                    label={"Ulice"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <FormInput
                    name={"houseNumber"}
                    type={"text"}
                    label={"Číslo domu"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <FormInput
                    name={"city"}
                    type={"text"}
                    label={"Město"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <FormInput
                    name={"zip"}
                    type={"text"}
                    label={"ZIP"}
                    errors={errors}
                    readonly={true}
                    register={register}
                />
                <button type="submit"
                        className="button-primary">
                    Aktualizovat profil
                </button>
            </form>
        </div>
    );
};

export default AccountForm;