import { Home, User, CreditCard, FileText, Users, HelpCircle, List } from "feather-icons-react";

export const portalSidebarLinks = [
    {
        label: "Přehled",
        link: "/portal/prehled",
        icon: <Home/>,
    },
    {
        label: "Můj účet",
        link: "/portal/muj-ucet",
        icon: <User/>,
    },
    {
        label: "Karta občana",
        link: "/portal/karta-obcana",
        icon: <CreditCard/>,
    },
    {
        label: "Historie transakcí",
        link: "/portal/historie",
        icon: <FileText/>,
    },
    {
        label: "Členové rodiny",
        link: "/portal/clenove-rodiny",
        icon: <Users/>,
    },
    /*{
        label: "Nastavení",
        link: "/portal/nastaveni",
        icon: <Settings/>,
    },*/
    {
        label: "Časté dotazy",
        link: "/portal/caste-dotazy",
        icon: <List/>,
    },
    {
        label: "Podpora",
        link: "/portal/podpora",
        icon: <HelpCircle/>,
    },
];