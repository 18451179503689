import React from 'react';

const Empty = ({children}) => {
    return (
        <div>
            {children}
        </div>
    );
};

export default Empty;