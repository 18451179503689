import React, {Fragment, useEffect, useState} from 'react';
import PortalHeader from "../../compontents/PortalHeader";
import {toast} from "react-toastify";
import Loader from "../../compontents/Loader";
import {getCardList} from "../../api/CardApi";
import CitizenCardList from "../../compontents/Citizen/CitizenCardList";
import BoxContent from "../../compontents/BoxContent";

const CitizenCard = () => {

	const [cardList, setCardList] = useState(null);

	useEffect(() => {
		fetchCardList().then((data) => {
			setCardList(data.cardList);
		}).catch((err) => console.error(err))
	}, [])

	async function fetchCardList() {
		try {
			return await getCardList();
		} catch (e) {
			console.error(e.message || e)
			toast.error("Při zpracování požadavku došlo k chybě.")
		}
	}

	return (
		<div>
			<PortalHeader
				headline={"Karta občana"}
			/>
			<div className="container mx-auto">
				<BoxContent>
					{cardList ?
						<Fragment>
							{cardList.length === 0 ?
								<div>No card</div> // todo: better message
								:
								<CitizenCardList data={cardList}/>}
						</Fragment>
						:
						<div className={"flex justify-center"}>
							<Loader/>
						</div>
					}
				</BoxContent>
			</div>
		</div>
	);
};

export default CitizenCard;