import React from 'react';
import {useSelector} from "react-redux";
import {userCreditSelector, userDataSelector} from "../../redux/selectors/userSelectors";
import {Link} from "react-router-dom";

const PortalNavbar = () => {

    const userSelector = useSelector(userDataSelector)
    const userCredit = useSelector(userCreditSelector)

    return (
        <ul className={"flex items-center justify-between bg-primary-300 py-4 px-5"}>
            <div>
                <h1 className={"text-white font-bold text-2xl"}>
                    <span className={"text-secondary-300"}>Bene</span>šov<span
                    className={"text-secondary-300"}><sup className={"icon-benesov text-sm "}></sup></span>Bene<span className={"text-secondary-300"}>fit!</span>
                </h1>
            </div>
            <div className={"space-x-5"}>
                {userCredit &&
                    <span className={"text-white"} title={"Bene kreditů"}>
                        <Link to={'/portal/historie'}>{userCredit} Bene</Link>
                    </span>
                }
                <span className={"text-white"}>
                    {userSelector &&
                        <Link to={'/portal/muj-ucet'}>
                            {userSelector.firstName + ' ' + userSelector.lastName}
                        </Link>
                    }
                </span>
            </div>
        </ul>
    );
};

export default PortalNavbar;