import {ApiAxios} from "../axios/axiosConfig";

export async function getCitizen() {

    return ApiAxios.get(`/citizen`).then(res => {
        if (res.data) {
            return res.data.data
        }
    }).catch(err => {
        console.error(err.message || err)
        return false
    })
}

export async function getChildren() {

    return ApiAxios.get(`/citizen/children`).then(res => {
        if (res.data) {
            return res.data.data
        }
    }).catch(err => {
        console.error(err.message || err)
        return false
    })
}

export async function getChild(id) {

    return ApiAxios.get(`/citizen/child/${id}`).then(res => {
        if (res.data) {
            return res.data.data
        }
    }).catch(err => {
        console.error(err.message || err)
        return false
    })
}