import * as yup from 'yup';

/* eslint-disable no-template-curly-in-string */
export const recoveryPasswordValidation = yup.object().shape({
    password: yup.string()
        .trim()
        .required('Heslo je povinné')
        .min(10, 'Heslo musí mít minimálně 10 znaků')
        .matches(/[a-z]/, 'Heslo musí obsahovat alespoň jedno malé písmeno')
        .matches(/[A-Z]/, 'Heslo musí obsahovat alespoň jedno velké písmeno')
        .matches(/[0-9]/, 'Heslo musí obsahovat alespoň jedno číslo')
        .matches(/[^a-zA-Z0-9]/, 'Heslo musí obsahovat alespoň jeden speciální znak'),

    passwordVerify: yup.string()
        .trim()
        .required('Kontrola hesla je povinná')
        .oneOf([yup.ref('password'), null], 'Hesla se musí shodovat'),
});