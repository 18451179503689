import {ApiAxios} from "../axios/axiosConfig";

export async function getCardList() {

	return ApiAxios.get(`/cards`).then(res => {
		if (res.data) {
			return res.data.data;
		}
	}).catch(err => {
		console.error(err.message || err)
		return false;
	})
}