import React from 'react';

const TouchPointTile = ({image, title, link}) => {
    return (
        <div>
            <div className={"hover:scale-105 duration-300"}>
                <div className={"relative"}>
                    <span className={"absolute top-2 left-3 text-white"}>{title}</span>
                    <img src={`../../img/points/${image}`} className={"shadow-md rounded-md"} alt={title}/>
                </div>
            </div>
        </div>
    );
};

export default TouchPointTile;