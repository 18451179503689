import React, {useEffect, useState} from 'react';
import AccountForm from "../../../compontents/Forms/Portal/AccountForm";
import BoxContent from "../../../compontents/BoxContent";
import Loader from "../../../compontents/Loader";
import {getCitizen} from "../../../api/CitizenApi";
import {toast} from "react-toastify";

const EditAccount = () => {

    const [citizen, setCitizen] = useState(null);

    useEffect(() => {
        getMe().then(() => console.info("Data loaded.."))
    }, [])

    async function getMe() {
        try {
            const data = await getCitizen()
            console.log(data)
            setCitizen(data.citizen)
        } catch (e) {
            console.error(e.message || e)
            toast.error("Při zpracování požadavku došlo k chybě.")
        }
    }

    return (
        <BoxContent label={'Upravit profil'} additionalClass={"max-w-4xl"}>
            {citizen ? <AccountForm defaultValues={citizen}/> : <Loader/>}
        </BoxContent>
    );
};

export default EditAccount;