import React from 'react';
import {Navigate} from "react-router-dom";
import PortalLayout from "../Layout/PortalLayout";

const ProtectedRoute = ({children}) => {

    // TODO: ověření
    let isAuthenticated = true;

    if (isAuthenticated) {
        return (
            <PortalLayout>
                {children}
            </PortalLayout>
        );
    } else {
        return <Navigate to="/login" replace/>;
    }
};

export default ProtectedRoute;